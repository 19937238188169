<template>
  <ul class="category-list">

    <li class="category-list__item" >
      <router-link class="category-list__item-link" :class="{ 'category-list__item-link--selected': category === 'latest' }" :to="store_latest_path(region)">Latest</router-link>
    </li>

    <li class="category-list__item" v-for="category in categories">
      <router-link class="category-list__item-link" :class="{ 'category-list__item-link--selected': showSubcategories(category) }" :to="category_path(region, category.name)">{{ category.name }}</router-link>
      <transition-collapse-height>
        <ul class="category-list__sublist" v-if="showSubcategories(category)">
          <li class="category-list__subitem" v-for="subcategory in category.subcategories">
            <router-link class="category-list__subitem-link" :class="{ 'category-list__subitem-link--selected': isCategorySelected(subcategory) }" :to="category_path(region, subcategory.name)">{{ subcategory.name }}</router-link>
          </li>
        </ul>
      </transition-collapse-height>
    </li>
  </ul>
</template>


<script>

import Category from "../../models/Category";
import TransitionCollapseHeight from "./transition-collapse-height"

export default {
  components:{
    TransitionCollapseHeight
  },
  props:{
    category: String,
    region: String
  },
  data(){
    return {
      categories: Category.categories
    }
  },
  mounted(){

  },
  computed:{

  },
  watch:{
    category(){
      console.log("category");
    }
  },
  methods:{
    isCategorySelected(category){
      return this.category === category.name;
    },
    showSubcategories(category){
      return this.category === category.name || this.categories.find( c => c.name === category.name).subcategories.some( s => s.name === this.category);
    }
  }
}

</script>


