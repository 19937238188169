import GraphQLTask from "../models/GraphQLTask";
//import {aTypedArray} from "core-js/internals/array-buffer-view-core";
import {Timestamp} from "firebase/firestore";
import { auth } from '../firebase'

export default class GraphQL {

    static async Call(){
        let instance = new this;
        return await instance.call();
    }

    static async CreateTask(){
        let instance = new this;
        return await instance.createTask();
    }

    get query(){
        throw new Error(`${this.constructor.name}  must implement query() method`);
    }

    get url(){
        throw new Error(`${this.constructor.name}  must implement url() method`);
    }

    get variables(){
        return { };
    }

    get payload(){
        return JSON.stringify({
            variables: this.variables,
            query: this.query
        })
    }

    get headers(){
        return { };
    }

    get use_admin_api(){
        return true;
    }

    async call(){
        return await fetch(this.url, {
            method: 'POST',
            headers: this.headers,
            body: this.payload,
        }).then((response) => response.json());
    }

    async createTask(){
        let task = new GraphQLTask();
        task.created_at = Timestamp.now();
        task.variables = this.variables;
        task.query = this.query;
        task.user = auth.currentUser.email;
        task.use_admin_api = this.use_admin_api;
        await task.save();
        return task;
    }

}