
export default  {
    categories: [
        { name: 'Dresses', handle: 'dresses', subcategories: [
            { name : 'Mini Dresses', handle: 'mini-dresses' },
            { name : 'Midi Dresses', handle: 'midi-dresses' },
            { name : 'Maxi Dresses', handle: 'maxi-dresses' },
            { name : 'Gowns', handle: 'gowns' },
            { name : 'Bride', handle: 'bride' },
        ]},
        { name: 'Skirts', handle: 'skirts', subcategories: [
            { name : 'Mini Skirts', handle: 'mini-skirts' },
            { name : 'Midi Skirts', handle: 'midi-skirts' },
            { name : 'Maxi Skirts', handle: 'maxi-skirts' },
        ]},
        { name: 'Shorts', handle: 'shorts', subcategories: [
            { name : 'Denim Shorts', handle: 'denim-shorts' },
            { name : 'Casual Shorts', handle: 'casual-shorts' },
            { name : 'Dress Shorts', handle: 'dress-shorts' },
            { name : 'Knee Length Shorts', handle: 'knee-length-shorts' },
        ]},
        { name: 'Pants', handle: 'pants', subcategories: [
            { name : 'Jeans', handle: 'jeans'},
            { name : 'Dress Pants', handle: 'dress-pants'},
            { name : 'Cropped Pants', handle: 'cropped-pants'},
            { name : 'Skinny Pants', handle: 'skinny-pants' },
            { name : 'Wide Leg & Flare Pants', handle: 'wide-leg-and-flare-pants' },
            { name : 'Leggings', handle: 'leggings' },
        ]},
        { name: 'Tops', handle: 'tops', subcategories: [
            { name : 'Singlets', handle: 'singlets' },
            { name : 'Vests', handle: 'vests' },
            { name : 'Short Sleeve Tees', handle: 'short-sleeve-tees' },
            { name : 'Long Sleeve Tees', handle: 'long-sleeve-tees' },
            { name : 'Short Sleeve Shirts', handle: 'short-sleeve-shirts' },
            { name : 'Long Sleeve Shirts', handle: 'long-sleeve-shirts' },
            { name : 'Blouses', handle: 'blouses' },
            { name : 'Shirts', handle: 'shirts' },
            { name : 'Crop Tops', handle: 'crop-tops' },
            { name : 'Short Sleeve Tops', handle: 'short-sleeve-tops' },
            { name : 'Long Sleeve Tops', handle: 'long-sleeve-tops' },
        ]},
        { name: 'Jumpsuits & Rompers', handle: 'jumpsuits-and-rompers', subcategories: [
            { name : 'Jumpsuits', handle: 'jumpsuits' },
            { name : 'Rompers', handle: 'rompers' },
        ]},
        { name: 'Knitwear', handle: 'knitwear', subcategories: [
            { name : 'Sweaters', handle: 'sweaters' },
            { name : 'Cardigans', handle: 'cardigans' },
        ]},
        { name: 'Coats & Jackets', handle: 'coats-and-jackets', subcategories: [
            { name : 'Coats', handle: 'coats' },
            { name : 'Bomber & Puffer Jackets', handle: 'bomber-and-puffer-jackets' },
            { name : 'Blazer Jackets', handle: 'blazer-jackets' },
            { name : 'Smart Jackets', handle: 'smart-jackets' },
            { name : 'Casual Jackets', handle: 'casual-jackets' },
            { name : 'Denim Jackets', handle: 'denim-jackets' },
        ]},
        { name: 'Loungewear & Activewear', handle: 'loungewear-and-activewear', subcategories: [
            { name : 'Bike Shorts', handle: 'bike-shorts' },
            { name : 'Tights', handle: 'tights' },
            { name : 'Tank Tops', handle: 'tank-tops' },
            { name : 'Sports Bras', handle: 'sports-bras' },
            { name : 'Sweat Shirts', handle: 'sweat-shirts' },
            { name : 'Sweat Pants', handle: 'sweat-pants' },
            { name : 'Leggings', handle: 'leggings' },
            { name : 'Hoodies', handle: 'hoodies' },
        ]},
        { name: 'Robes & Kikomos', handle: 'robes-and-kikomos', subcategories: [
            { name : 'Robes', handle: 'robes' },
            { name : 'Kikomos', handle: 'kikomos' },
        ]},
        { name: 'Intimates', handle: 'intimates', subcategories: [
            { name : 'Bras', handle: 'bras' },
            { name : 'Bralettes', handle: 'bralettes' },
            { name : 'Briefs', handle: 'briefs' },
        ]},
        { name: 'Shoes', handle: 'shoes', subcategories: [
            { name : 'Boots', handle: 'boots' },
            { name : 'Heels', handle: 'heels' },
            { name : 'Flat Shoes', handle: 'flat-shoes' },
            { name : 'Sneakers', handle: 'sneakers' },
            { name : 'Sandals', handle: 'sandals' },
            { name : 'Slip Ons', handle: 'slip-ons' },
        ]},
        { name: 'Swimwear', handle: 'swimwear', subcategories: [
            { name : 'Swim Tops', handle: 'swim-tops' },
            { name : 'Swim Bottoms', handle: 'swim-bottoms' },
            { name : 'Swim Sets', handle: 'swim-sets' },
            { name : 'Swim One Piece', handle: 'swim-one-piece' }
        ]},
        { name: 'Accessories', handle: 'accessories', subcategories: [
            { name : 'Belts', handle: 'belts' },
        ]},
        { name: 'Miscellaneous', handle: 'miscellaneous', subcategories: []},
    ],

    nameToHandle(name){
        return name.toLowerCase().replace(' & ', '-and-').trim().replace(/ /g,'-');
    },

    handleToName(handle){
        return handle.toLowerCase().replace('-and-', ' & ').trim().replace(/-/g,' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    },

    validateCategories() {
        for (let category of this.categories) {
            let handle = this.nameToHandle(category.name);
            if (handle !== category.handle){
                console.log(handle + ' !== ' + category.handle)
                return false;
            }
            if (category.name !== this.handleToName(category.handle)){
                console.log(category.name  + ' !== ' + this.handleToName(category.handle));
                return false;
            }

            for (let subcategory of category.subcategories) {
                let handle = this.nameToHandle(subcategory.name);
                if (handle !== subcategory.handle){
                    console.log(handle + ' !== ' + subcategory.handle)
                    return false;
                }
                if (subcategory.name !== this.handleToName(subcategory.handle)){
                    console.log(subcategory.name  + ' !== ' + this.handleToName(subcategory.handle));
                    return false;
                }
            }
        }
        return true;
    },

    isParentCategory(category_name){
        console.log("is", category_name)
      return this.categories.some(c => c.name === category_name);
    },

    toFlatArray(){
       let result = this.categories.reduce((output, category) =>{
            output.push({ title: category.name, category: category.name, subcategory: category.name });
            for(let subcategory of category.subcategories){
                output.push({ title: `${category.name} - ${subcategory.name}`, category: category.name, subcategory: subcategory.name });
            }
            return output;
        }, []);
       result.push({ title: 'Not Set', category: null, subcategory: null });
       return result;
    }
}