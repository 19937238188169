import GraphQL from "./GraphQL";

export default class ShopifyGraphQL extends GraphQL {

    get url(){
        return 'https://spell.myshopify.com/admin/api/2022-07/graphql.json';
    }
    
    get headers(){
        return {
            'Content-Type': 'application/json',
            'X-Shopify-Access-Token': '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',
            'Access-Control-Allow-Origin':'*'
        };
    }
}