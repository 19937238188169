import BaseModel from "./BaseModel";
import {getDocs, limit, orderBy, query, where, startAt, startAfter} from "firebase/firestore";

export default class ShopifyProduct extends BaseModel {
    static data_name = "shopify_products";

    static DisplayState = {
        NOT_SET: 0,
        HIDE_FROM_SITE: 10,
        SHOW_ON_SITE: 20,
        PROMOTE_ON_SITE: 30,

        name(state) {
            switch (state) {
                case this.HIDE_FROM_SITE: return "Hide From Site";
                case this.SHOW_ON_SITE: return "Show On Site";
                case this.PROMOTE_ON_SITE: return "Promote On Site";
                default: return "Not Set";
            }
        },

        toNameValueArray(){
            return [
                { value: this.NOT_SET, name:  this.name(this.NOT_SET)},
                { value: this.HIDE_FROM_SITE, name:  this.name(this.HIDE_FROM_SITE)},
                { value: this.SHOW_ON_SITE, name:  this.name(this.SHOW_ON_SITE)},
                { value: this.PROMOTE_ON_SITE, name:  this.name(this.PROMOTE_ON_SITE)},
            ]
        }
    };

    get display_state_name() {
        return this.constructor.DisplayState.name(this.display_state);
    }

    get category_object(){
        return this.subcategory ?
            { title: this.category == this.subcategory ? this.category : `${this.category} - ${this.subcategory}`, category: this.category, subcategory: this.subcategory } :
            { title: 'Not Set', category: null, subcategory: null };
    }

    set category_object(value){
         this.category = value.category;
         this.subcategory = value.subcategory;
    }


    static async pageForSite(shopify_site_document_id, start_after_published_date = null){
        let q = query(this.collectionRef,
                    where('display_state', 'in', [ShopifyProduct.DisplayState.SHOW_ON_SITE, ShopifyProduct.DisplayState.PROMOTE_ON_SITE]),
                    where('shopify_site_document_id', '==', shopify_site_document_id),
                    where('available', '==', true),
                    orderBy("published_at", "desc"),
                    limit(24));

        if(start_after_published_date) {
            q = query(q, startAfter(start_after_published_date));
        }

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static async pageForLatest(start_after_published_date = null){
        let q = query(this.collectionRef,
            where('display_state', '==', ShopifyProduct.DisplayState.PROMOTE_ON_SITE),
            where('available', '==', true),
            orderBy("published_at", "desc"),
            limit(24));

        if(start_after_published_date) {
            q = query(q, startAfter(start_after_published_date));
        }

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static async pageForCategory(category, is_parent_category, start_after_published_date = null){
        let q = query(this.collectionRef,
            where('display_state', 'in', [ShopifyProduct.DisplayState.SHOW_ON_SITE, ShopifyProduct.DisplayState.PROMOTE_ON_SITE]),
            where(is_parent_category ? 'category' :  'subcategory', '==', category),
            where('available', '==', true),
            orderBy("published_at", "desc"),
            limit(24));

        if(start_after_published_date) {
            q = query(q, startAfter(start_after_published_date));
        }

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }


    static async requiresApprovalPage(start_after_published_date){
        let q = query(this.collectionRef,
                        where('display_state', '==', 0),
                        orderBy("published_at", "desc"),
                        limit(100));

        if(start_after_published_date) {
            q = query(q, startAfter(start_after_published_date));
        }

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static async updateProductsWithMissingCategories(potential_site){
        let products =  await this.whereAnd(['category', '==', null], ['potential_shopify_site_document_id', '==', potential_site.document_id]);
        console.log("products", products.length)
        for(let product of products){
            await product.setMissingCategory(potential_site);
        }
    }

    async setMissingCategory(potential_site){
        let category_mapping = potential_site.categoryMappingForProductType(this.product_type);
        if(category_mapping.category){
            this.category = category_mapping.category;
            this.subcategory = category_mapping.subcategory;
            if(this.display_state < category_mapping.display_state)
                this.display_state = category_mapping.display_state;
            await this.save();
            console.log("product", this.title, this.product_type, this.category, this.subcategory);
        }
    }

    get small_image_url(){
       return this.images.length > this.main_image_index ? this.images[this.main_image_index].src.replace(".jpg", "_300x.jpg")
                                                        .replace(".png", "_300x.png") : "";
    }

    get cropped_image_url(){
        if(this.images.length < 1)
            return "";
        let image = this.images[0];
        if(image.width == image.height){
            return  image.src;
        } else {
            let size = Math.min(image.width, image.height);
            let param = `_${size}x${size}_crop_center`;
            return image.src.replace(".jpg", param + ".jpg")
                            .replace(".png", param + ".png");
        }
    }

}