<template>
    <div class="photo-selector" v-if="show">
        <div class="photo-selector__images-container">
            <div class="photo-selector__images">
                <div class="photo-selector__image" v-for="file in files" :key="file.id">
                   <img class="photo-selector__img"
                        :src="file.small_image"
                        draggable="true"
                        @dragenter="cancelEvent"
                        @dragover="cancelEvent"
                        @dragstart="dragstart($event, file)" />
                </div>
                <div class="photo-selector__image photo-selector__image--fill"></div>
                <div class="photo-selector__image photo-selector__image--fill"></div>
              <div class="photo-selector__loading" v-if="is_loading_files" >
                <div class="photo-selector__loading-dot"></div>
                <div class="photo-selector__loading-dot"></div>
                <div class="photo-selector__loading-dot"></div>
              </div>
                <a class="photo-selector__loadmore" v-else @click.prevent="loadMoreImages">Load More</a>

            </div>
        </div>
    </div>
</template>


<script>

  export default {
    data () {
      return {
      }
    },
    mounted(){
      console.log("PhotoSelect.mounted");
      this.loadMoreImages();
    },
    computed: {
      show(){
        //return ImageSelectModel.show_photo_selector;
        return true;
      },
      is_loading_files(){
        return this.$store.getters.is_loading_files;
      },
      files(){
        return this.$store.getters.files;
      }
    },
    methods: {
      loadMoreImages(){
        this.$store.dispatch('loadMoreImages');
      },
      dragstart(event, file){
        console.log("drag start file", file);
        event.dataTransfer.setData("file", JSON.stringify(file));
      }
    }
  }
</script>
