import { createWebHistory, createRouter } from "vue-router";
import Home from '../views/home.vue'
import { auth } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { transparentHeader: true },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { transparentHeader: true },
    component: () => import(  '../views/gmail_login')
  },
  {
    path: '/not-found',
    name: '404',
    component: () => import('../views/404')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/privacy_policy')
  },
  {
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: () => import('../views/terms_of_service')
  },



  {
    name: 'Admin Dashboard',
    path: '/admin',
    meta: { requires_auth: true },
    component: () => import('../views/admin/dashboard'),
    children: [
      {
        name: 'Form',
        path: 'form',
        meta: { requires_forms: true, links: [], fullwidth: true },
        component: () => import('../views/admin/form/index'),
      },
      {
        name: 'Users',
        path: 'users',
        meta: { requires_admin: true, links: []},
        component: () => import('../views/admin/users/index'),
      },
      {
        name: 'Blog Posts',
        path: 'blog_posts',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Blog Posts',
            path: '',
            meta: {  requires_admin: true, links: []},
            component: () => import('../views/admin/blog_posts/index'),
          },
          {
            name: 'Edit Blog Post',
            path: ':blog_id/article/:id',
            meta: { requires_admin: true,  links: ['/admin/blog_posts'] },
            component: () => import('../views/admin/blog_posts/edit')
          }
        ]
      },
      {
        name: 'Collections',
        path: 'collections',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Collections',
            path: '',
            meta: { requires_admin: true,  links: [] },
            component: () => import('../views/admin/collections/index')
          },
          {
            name: 'Edit Collection',
            path: ':type/:id/:handle',
            meta: { requires_admin: true,  links: ['/admin/collections'] },
            component: () => import('../views/admin/collections/edit')
          }
        ]
      },
      {
        name: 'Products',
        path: 'products',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Products',
            path: '',
            meta: { requires_admin: true,  links: [] },
            component: () => import('../views/admin/products/index')
          }
        ]
      },
      {
        name: 'Lives',
        path: 'lives',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Lives',
            path: '',
            meta: { requires_admin: true, links: []},
            component: () => import('../views/admin/live/index'),
          },
          {
            name: 'Edit Live',
            path: ':id',
            meta: { requires_admin: true,  links: ['/admin/lives'], fullwidth: true },
            component: () => import('../views/admin/live/edit')
          }
        ]
      },
      {
        name: 'OCR',
        path: 'extract_text_from_images',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'OCR',
            path: '',
            meta: { requires_admin: true,  links: ['/admin/extract_text_from_images/new'] },
            component: () => import('../views/admin/extract_text_from_images/index'),
          },
          {
            name: 'New Text Extraction',
            path: 'new',
            meta: { requires_admin: true,  links: ['/admin/extract_text_from_images/'] },
            component: () => import('../views/admin/extract_text_from_images/new')
          },
          {
            name: 'OCR Results',
            path: ':id',
            meta: { requires_admin: true,  links: ['/admin/extract_text_from_images'] },
            component: () => import('../views/admin/extract_text_from_images/item')
          }
        ]
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
 // window.scrollTo(0, 0);
  const requires_auth = to.matched.some(x => x.meta.requires_auth);
  const requires_admin = to.matched.some(x => x.meta.requires_admin);
  const requires_forms = to.matched.some(x => x.meta.requires_forms);

  //console.log("auth.currentUser", auth.currentUser ? auth.currentUser.email : "no user");

  if (!requires_auth) { console.log("..route 1")
    next();
  }else if(!auth.currentUser) { console.log("..route 2")
    next('/login');
  } else { console.log("..route 3")
    next();

    auth.currentUser.getIdTokenResult().then(token => {
      console.log("token.claims", token.claims);
      if ((requires_admin && token.claims.admin != true) || (requires_forms && token.claims.forms != true)){
        if(token.claims.forms){ console.log("..route 4")
          next('/admin');
        } else { console.log("..route 5")
          next('/login');
        }
      }else console.log("..route 6")
    })
  }
});

export default router
