<template>
    <img :src="this.src" />
</template>

<script>
  export default {
    props: {
      file: Object
    },
    data () {
      return {
        src: ""
      }
    },
    mounted() {
      this.setSrc();
    },
    watch:{
      file(newValue){
        this.setSrc();
      }
    },
    methods: {
      setSrc(){
        this.src = this.file.small_image;
        let image = new Image();
        image.onload = () => { this.src = this.file.medium_image; };
        image.src = this.file.medium_image;
      }
    }
  }
</script>