export default {
  methods:{

    shopifyImageUrl(url, maxPercentDesktop = 100, maxPercentMobile = 100, maxPx = 999999, compressionRate = 0.8) {
      let viewPort = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      let maxPercent = viewPort <= 960 ? maxPercentMobile : maxPercentDesktop;
      let maxSize = Math.min(maxPx, viewPort * (maxPercent / 100));
      let imageResolution = maxSize * window.devicePixelRatio * compressionRate;
      let size = Math.min(2000, Math.max( 100, Math.floor(imageResolution / 100) * 100));
      let ext = `_${size}x${size*1.5}_crop_center.`;
      let parts = url.split('.');
      let lastPart = parts.pop();
      return parts.join('.') + ext + lastPart;
    },

    isMobile(){
      return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 960;
    }

  }
}
