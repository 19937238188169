<template>
  <div style="position: relative;">
    <div contenteditable="true"
        @input="update"
        @focus="focus"
        @blur="blur"
         @paste="paste"
        v-html="valueText"
        @keyup.ctrl.delete="$emit('delete-row')"
    ></div>

    <a @click="link__clicked" style="position: absolute; bottom:4px; right:28px; border:solid 1px rgba(0,0,0,0.2); padding:2px 6px; font-family:serif; font-size:14px; cursor:pointer; font-weight:800;">a</a>
    <a @click="bold__clicked" style="position: absolute; bottom:4px; right:4px; border:solid 1px rgba(0,0,0,0.2); padding:2px 6px; font-family:serif; font-size:14px; cursor:pointer; font-weight:800;">B</a>

  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      focusIn: false,
      valueText: ''
    }
  },
  computed: {
    localValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.$emit('update:value', newValue)
      }
    }
  },
  watch: {
    localValue(newVal) {
      if (!this.focusIn) {
        this.valueText = newVal
      }
    }
  },
  created() {
    this.valueText = this.value
  },
  methods: {
    update(e) {
      this.localValue = e.target.innerHTML
    },
    focus() {
      this.focusIn = true
    },
    blur() {
      this.focusIn = false
    },
    paste(event){
      console.log("paste", event)
      event.preventDefault();
      let contentOnBlur = (event.originalEvent || event).clipboardData.getData('text/plain') || prompt('Paste something..');
      contentOnBlur = contentOnBlur.replace(/(<([^>]+)>)/ig,'');
      document.execCommand('insertText', false, contentOnBlur);
    },
    link__clicked(event){
      let link = prompt("Enter URL", "https://aus.spell.co/");
      document.execCommand('createLink', false, link);
    },
    bold__clicked(event){
      document.execCommand('bold', false, null);
    }
  }
}
</script>