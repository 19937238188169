export default {
    methods: {
        scrollToTop(onNextTick = false) {
            if(onNextTick) {
                this.$nextTick(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
            }else{
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        },

        cancelEvent(e) {
            e.preventDefault();
        }
    }
}