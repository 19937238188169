import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import router from '../router/router'
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../firebase";
import User from "../models/User";
import Session from "../models/Session";

import GetFiles from "../graph_ql/GetFiles";
import GraphQLTask from "../models/GraphQLTask";


const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage || {},
});




const store = createStore({
  plugins: [vuexLocal.plugin],
  state: {
    properties: [],
    show_mobile_menu: false,
    files: [],
    is_loading_files: false
  },
  getters: {
    properties: (state) => state.properties,
    show_mobile_menu: (state) => state.show_mobile_menu,
    files: (state) => state.files,
    has_files: (state) => state.files.length > 0,
    is_loading_files: (state) => state.is_loading_files
  },
  mutations: {
    setProperties(state, val) {
      state.properties = val;
    },
    setShowMobileMenu(state, val){
      state.show_mobile_menu = val;
    },
    appendFiles(state, files){
      state.files.push(...files);
      state.is_loading_files = false;
    },
    isLoadingFiles(state, value){
      state.is_loading_files = value;
    }
  },


  actions: {
    async logout({ commit }) {
      await auth.signOut();
      router.push('/login');
    },

    async loadMoreImages(context) {

      console.log("fetch files" );
      context.commit('isLoadingFiles', true);

      let cursor = context.getters.has_files ? context.getters.files[context.getters.files.length - 1].cursor : null;
      let getFiles = new GetFiles(cursor);
      let task = await getFiles.createTask();
      console.log(" loadImages task.document_id", task.document_id);

      GraphQLTask.onDocumentUpdate(task.document_id, document => {
        if(document.response && document.response.data){
          console.log(" loadImages task updated", document.response.data);
          let files = document.response.data.files.edges.reduce((output, item) =>{
             let file = item.node;
             file.image = file.image.src;
             file.small_image = file.small_image.src;
             file.medium_image = file.medium_image.src;
             file.cursor = item.cursor;
             output.push(file)
             return output;
          }, []);

          console.log("files ", files);

          context.commit('appendFiles', files);
        }
      });
    },

    toggleMobileMenu({commit, getters}){
      commit('setShowMobileMenu', !getters.show_mobile_menu);
    },
    closeMobileMenu({commit, getters}){
      console.log("close menu");
      commit('setShowMobileMenu', false);
    },
    async gmailSignIn({dispatch}){
      let result = await signInWithPopup(auth, new GoogleAuthProvider());
      await User.saveUser(result.user, GoogleAuthProvider.credentialFromResult(result));
      const token = await auth.currentUser.getIdTokenResult(true);

      if (token.claims.admin) { // User is admin
        await router.push('/admin');
        return;
      }

      // Wait for custom claims to be updated.
      let unsubscribe = Session.onDocumentUpdate(token.claims.user_id, session => {

        auth.currentUser.getIdTokenResult(true).then((tokenResult) => {
            if (tokenResult.claims.admin) { // User is admin
              unsubscribe();
              router.push('/admin')
            }
        });
      });
    },
  }
});

export default store
