import BaseModel from "./BaseModel";

export default class User extends BaseModel {
    static data_name = 'users';

    static saveUser(user, credential) {
        let instance = new User();
        instance.document_id = user.email;
        instance.uid = user.uid;
        instance.updated_at = new Date();
        instance.email = user.email;
        instance.display_name = user.displayName;
        instance.provider_data = user.providerData;
        instance.phone_number = user.phoneNumber;
        instance.photo_url = user.photoURL;
        instance.email_verified = user.emailVerified;
        instance.is_anonymous = user.isAnonymous;
        instance.user_access_token = user.accessToken
        instance.credential = {
            access_token: credential.accessToken,
            id_token: credential.idToken,
            pending_token: credential.pendingToken,
            provider_id: credential.providerId,
            sign_in_method: credential.signInMethod
        };
        console.log("user", instance);

        return instance.save();
    }
}