<template>
   <div class="home" style="padding: 50px;">
     <router-link style="text-decoration:none !important;" to="/admin">
       <h1>Helloe</h1>
     </router-link>
   </div>
</template>


<script>

   import Session from "../models/Session";
   import {auth} from "../firebase";
   import router from "../router/router";

   export default {
      data(){
         return {

         }
      },
     mounted() {
      /* console.log("check session");

       let unsubscribe = Session.onDocumentUpdate('LCqVxt6EDuWM4qsAGFodRqMQ0Pe2', session => {

         console.log("session updated")

         auth.currentUser.getIdTokenResult(true).then((tokenResult) => {
           console.log("tokenResult.claims", tokenResult.claims);
         });
       });
*/
     },
     metaInfo: {
         title: 'Hello',
         meta: [{ name: "description", content:'Hello There' }]
      },
      computed: {
      },
      methods:{

      }
   }
</script>
