import { createApp } from "vue";

//import { onAuthStateChanged } from "firebase/auth";

import App from './app.vue'
import router from './router/router'
import store from './store/store'

router.$store = store;

import { auth } from './firebase'
import './assets/scss/app.scss'

console.log("main.js");

//let app;

//onAuthStateChanged(auth, user => {
// if(app) return;

let app = createApp(App)

app.use(store);
app.use(router);

const mixins = require.context('./mixins/', true, /\.js$/);

mixins.keys().forEach(key => {
  app.mixin(mixins(key).default);
});


const directives = require.context('./directives/', true, /\.js$/);

directives.keys().forEach(key => {
  const directive = directives(key).default;
  const name = key.replace("./", "").replace(".js", "");
  app.directive(name, directive);
});


const vueComponents = require.context('./components/', true, /\.(vue|js)$/);

vueComponents.keys().forEach(key => {
  const component = vueComponents(key).default;
  const name = component.name
      ? component.name
      : key.replace(/\.(\/|vue|js)/g, '').replace(/(\/|-|_|\s)\w/g, (match) => match.slice(1).toUpperCase());

  console.log('component', name);
  app.component(name, component);
});


app.mount('#app');
//});


/*import Vue from 'vue'
import VueMeta from 'vue-meta'
import { onAuthStateChanged } from "firebase/auth";

import App from './app.vue'
import router from './router/router'
import store from './store/store'

import { auth } from './firebase'
import './assets/scss/app.scss'

Vue.config.productionTip = false;

Vue.use(VueMeta);

const mixins = require.context('./mixins/', true, /\.js$/);

mixins.keys().forEach(key => {
  Vue.mixin(mixins(key).default);
});

const directives = require.context('./directives/', true, /\.js$/);

directives.keys().forEach(key => {
  const directive = directives(key).default;
  const name = key.replace("./", "").replace(".js", "");
  Vue.directive(name, directive);
});


const vueComponents = require.context('./components/', true, /\.(vue|js)$/);

vueComponents.keys().forEach(key => {
  const component = vueComponents(key).default;
  const name = component.name
    ? component.name
    : key.replace(/\.(\/|vue|js)/g, '').replace(/(\/|-|_|\s)\w/g, (match) => match.slice(1).toUpperCase());

  console.log('component', name);
  Vue.component(name, component);
});


console.log("main.js");


let app;

onAuthStateChanged(auth, user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  //if (user) {
  //  store.dispatch('fetchUserProfile', user)
  //}
})


*/