import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCN0gIk8xLvhb1Q25LITvs_SoKqilv0q48",
  authDomain: "elovibe-firebase.firebaseapp.com",
  projectId: "elovibe-firebase",
  storageBucket: "elovibe-firebase.appspot.com",
  messagingSenderId: "545863687671",
  appId: "1:545863687671:web:410d228a5793899c80e2f2",
  measurementId: "G-CMZ909X70Q"
};

const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebaseApp);

// utils
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, "australia-southeast1");

//connectFirestoreEmulator(db, 'localhost', 5002);
//connectFunctionsEmulator(functions, 'localhost', 5001);

export {
  db,
  auth,
  functions
}


