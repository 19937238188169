<template>
  <div id="app">

<!--    <layout-header v-if="show_header"></layout-header> -->

    <router-view />

  </div>
</template>

<script>
export default {
  mounted(){
    let maxPx = 400;
    let maxPercent = 100;
    let compressionRate = 1;
    let viewPort = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    let maxSize = Math.min(maxPx, viewPort * (maxPercent / 100));
    let imageResolution = maxSize * window.devicePixelRatio * compressionRate;
    let size = Math.min(4000, Math.max( 400, Math.floor(imageResolution / 400) * 400));
    console.log("viewPort", document.documentElement.clientWidth, window.innerWidth, window.devicePixelRatio, viewPort, maxSize, imageResolution, size);
  },
  computed:{
    show_header(){
      return this.$route.meta.requiresAuth !== true
    }
  }
}
</script>
