import ShopifyGraphQL from "./ShopifyGraphQL";

export default class GetFiles extends ShopifyGraphQL {

    constructor(cursor){
        super();
        this.cursor = cursor;
    }

    get variables(){
        return {
            cursor: this.cursor
        }
    }

    get query(){
       return `
query($cursor: String) {
    files ( first: 50, sortKey: CREATED_AT, reverse: true, query: "media_type:image", after: $cursor ) {
        edges {
            cursor
            node {
                createdAt
                alt
                ... on MediaImage {
                    id
                    image {
                       src
                    }
                    small_image: image {
                       src: transformedSrc(maxWidth: 200) 
                    }
                    medium_image: image {
                       src: transformedSrc(maxWidth: 1000) 
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
        }
    }
}`;
    }
}