<template>
  <div class="collection-product">
    <div class="collection-product__image-container">

      <router-link class="collection-product__image-link" :to="product_url" :data-active-index="current_image_index">
        <div class="collection-product__image-scroll" ref="imageScroll" >
          <div class="collection-product__image" v-for="(image, index) in images" :key="index">
            <img class="collection-product__img" v-if="index < loaded_image_count" :alt="product.title" :src="shopifyImageUrl(image.src, image_width_percent_desktop, image_width_percent_mobile)" />
          </div>
        </div>

        <div class="collection-product__scroll-arrow collection-product__scroll-arrow--prev" @click.prevent="scrollToPrev__clicked"></div>
        <div class="collection-product__scroll-arrow collection-product__scroll-arrow--next" @click.prevent="scrollToNext__clicked"></div>

        <div class="collection-product__pagination">
          <div class="collection-product__pagination-dot" v-for="(image, index) in images" :key="index"></div>
        </div>
      </router-link>
    </div>

    <router-link class="collection-product__details" :to="product_url" >
      <div class="collection-product__title-container">
        <div class="collection-product__text collection-product__text--title">{{ product.title }}</div>
        <div class="collection-product__text collection-product__text--site">{{ product.site_name }}</div>
        <span class="hide--mobile hide--larger-than-mobile">{{ product.display_state }} {{ expanded }} </span>
      </div>
      <div class="collection-product__text collection-product__text--price">
        <div class="collection-product__price-previous" v-if="product.on_sale">
          {{ product.currency_symbol }}{{ product.compare_at_price_min.toFixed(2) }}
          <span v-if="product.compare_at_price_is_range">- {{ product.currency_symbol }}{{ product.compare_at_price_max.toFixed(2) }} </span>
        </div>
        <div>
          {{ product.currency_symbol }}{{ product.price_min.toFixed(2) }}
          <span v-if="product.price_is_range">- {{ product.currency_symbol }}{{ product.price_max.toFixed(2) }} </span>
          <span class="collection-product__currency">{{ product.currency }}</span>
        </div>
      </div>
    </router-link>


  </div>
</template>




<script>
import ShopifyProduct from "../../models/ShopifyProduct";

export default {
  props: {
    product: ShopifyProduct,
    product_url: String,
    index:  Number,
    expanded: Boolean
  },
  data() {
    return {
      current_image_index: 0,
      image_scroll_observer: null,
      images: [],
      loaded_image_count: 2,
      image_width_percent_desktop: 32
    }
  },
  mounted() { //console.log("collection-product mounted");
    this.initialize();
  },
  computed:{
    image_width_percent_mobile(){ //console.log("collection-product image_width_percent_mobile");
      return (this.index+1) % 5 == 0 ? 100 : 50;
    }
  },
  methods: {
    initialize() {
      this.images = this.product.images.slice(0, 6);
      this.$nextTick(this.createImageScrollObserver);
    },
    createImageScrollObserver() {
      if (window.IntersectionObserver) {
        this.image_scroll_observer = new IntersectionObserver ( this.image_scroll_observer__callback, {  root: this.$refs.imageScroll, rootMargin: "0px", threshold: 0.5 });
        for (const element of this.$refs.imageScroll.children) {
          this.image_scroll_observer.observe(element)
        }
      }
    },
    image_scroll_observer__callback(entries){
      entries.forEach( entry => {
        if (entry.isIntersecting) {
          this.current_image_index = Array.from(entry.target.parentNode.children).indexOf(entry.target);
          if(this.current_image_index > 0) {
            this.loaded_image_count = this.images.length;
          }
        }
      })
    },
    scrollToChild(index){
      let scrollRect = this.$refs.imageScroll.getBoundingClientRect();
      if(index >= 0 && index < this.$refs.imageScroll.children.length){
        let rect = this.$refs.imageScroll.children.item(index).getBoundingClientRect();
        this.$refs.imageScroll.scroll({ left: (rect.left - scrollRect.left) + this.$refs.imageScroll.scrollLeft, behavior: 'smooth' });
      }
    },
    scrollToNext__clicked(event) {
      this.scrollToChild(Math.min(this.product.images.length - 1, this.current_image_index + 1));
    },
    scrollToPrev__clicked(event) {
      this.scrollToChild(Math.max(0, this.current_image_index - 1));
    }
  }
}
</script>
