<template>
   <div class="product-details">

     <h2 class="product-details__title hide--mobile">{{ product.title }}</h2>

     <router-link class="product-details__site hide--mobile" :to="store_site_path(region, product.shopify_site_document_id)">{{ product.site_name }}</router-link>

     <div class="product-details__price hide--mobile">
        {{ product.currency_symbol }}{{ product.variants[0].price }} <span class="collection-product__currency">{{ product.currency }}</span>
      </div>

      <div class="product-details__options">
        <div class="product-details__option" v-for="(option, option_index) in product.options">
          <h4 class="product-details__option-name">{{ option.name }}</h4>
          <div class="product-details__option-buttons">
            <template v-for="value in option.values">
              <input class="product-details__option-radio" type="radio" :name="option.name" :id="`${ option.name }-${ value}`" :value="value" v-model="selections[option_index]" :disabled="!isVariantAvailable(option_index, value )" />
              <label class="product-details__option-label" :for="`${ option.name }-${ value}`">
                {{ value }}
              </label>
            </template>
          </div>
        </div>
      </div>

      <a :href="product.origin_url" class="button product-details__buy-button">Buy</a>

     <!--  -->
      <div class="product-details__description"  >
        <div v-html="description"></div>
        <div class="product-details__description-extra" :style="{'max-height': (show_full_description ? $refs.description_extra.clientHeight : 0) + 'px' }">
          <div class="product-details__description-extra-inner" ref="description_extra" v-html="description_extra"></div>
        </div>
      </div>

      <a  class="product-details__read-more-link"  v-if="description_extra.length > 0" @click="show_full_description = !show_full_description">
        <span v-if="show_full_description">Read Less</span>
        <span v-else>Read More</span>
      </a>

      <h1 v-if="selected_variant">var: {{ selected_variant.title }}</h1>
    </div>
</template>




<script>
import ShopifyProduct from "../../models/ShopifyProduct";

export default {
  props: {
    product: ShopifyProduct,
    region: String,
    index:  Number,
    expanded: Boolean
  },
  data() {
    return {
      selections: new Array(3),
      description:  '',
      description_extra: '',
      show_full_description: false,
    }
  },
  mounted() { //console.log("collection-product-details mounted");
    this.initialize();
  },
  computed:{
    selected_variant() {
      return this.product.variants.find(variant => {
        // console.log("sv", variant.option1, this.selections[0], variant.option2, this.selections[1], variant.option3, this.selections[2]);
        return variant.option1 === this.selections[0] &&
            variant.option2 === this.selections[1] &&
            variant.option3 === this.selections[2]
      });
    }
  },
  methods: {
    initialize() {
      this.setSelections();
      this.setDescription();
    //  this.getInventoryFromOrigin();
    },
    getInventoryFromOrigin(){
      fetch(this.product.origin_url).then(response => response.text()).then(html => {
        let doc = (new DOMParser()).parseFromString(html, 'text/html');
        let schemas = doc.querySelectorAll('script[type="application/ld+json"]');
        for(let schema of schemas){
          let json = JSON.parse(schema.innerText);
          if(json['@type'] && json['@type'].toLowerCase() == 'product' && json.offers){
            this.parseProductSchema(json);
          }
        }
      });
    },
    parseProductSchema(json){
      console.log("product_schema", json)
      for(let variant of this.product.variants){
        let offer = json.offers.find(o => o.itemOffered && o.itemOffered.sku == variant.sku)
        if(offer && offer.availability){
          variant.available = offer.availability.toLowerCase().includes("instock");
        }
      }
    },
    setSelections(){
      let available_variant = this.product.variants.find( v => v.available )
      if(available_variant){
        this.selections[0] = available_variant.option1;
        this.selections[1] = available_variant.option2;
        this.selections[2] = available_variant.option3;
      }
    },
    setDescription(){
      let tmp = document.implementation.createHTMLDocument("New").body;
      tmp.innerHTML = this.product.body_html;
      let parts = (tmp.textContent || tmp.innerText || "").split('\n').map(item => item.trim()).filter( item => item.length > 0) // .replace(/\n/g, '<br /><br />')

      //if(this.product.document_id == 'spell_6611355402313' )
       // console.log("p", parts)

      let description = '';
      let description_extra = '';
      let addToDescription = true;

      for(let part of parts){
        if(addToDescription){
          description += part;
          if(description.length > 100){
            addToDescription = false;
          }
        }else{
          description_extra += part
        }
      }

      this.description = description;
      this.description_extra = description_extra;
     // console.log("this.description", this.description);
    },
    isVariantAvailable(option_index, option_value){
      switch(option_index){
        case 0: return this.product.variants.filter( v => {
          return  v.option1 == option_value && v.available;
        }).length > 0;

        case 1: return this.product.variants.filter( v => {
          return v.option1 == this.selections[0] && v.option2 == option_value && v.available;
        }).length > 0;

        default: return this.product.variants.filter( v => {
          return v.option1 == this.selections[0] && v.option2 == this.selections[1] && v.option3 == option_value && v.available;
        }).length > 0;
      }
    }
  },
  watch:{
    product(){ console.log("collection-product-details.watch product");
      this.initialize();
    }
  }
}
</script>
